/* eslint-disable @nx/enforce-module-boundaries */
import { useDisclosure, useToast } from '@mybridge/ui/hooks';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { createContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserJobPreferences,
  removeUserJobPreferencesResume,
  setUserJobPreferences,
} from 'v4/store/actions/Job.Action';
import {
  getAllConnectionsData,
  getDegreeConnectionsByUserId,
} from 'v4/store/actions/connection.actions';
import { listOfReceivedTestimonial } from 'v4/store/actions/testimonial.actions';
import {
  addCertificate,
  addEducation,
  addExperience,
  addMilestone,
  addSkill,
  deleteCertificate,
  deleteEduction,
  deleteExperience,
  deleteManySkills,
  deleteMilestone,
  deleteSkill,
  getCertificate,
  getEducation,
  getExperience,
  getMilestone,
  getMyActivity,
  getProfileSkills,
  getProfileViewers,
  getUserAnalytics,
  getUserProfileInfo,
  updateCertificate,
  updateEducation,
  updateExperience,
  updateMilestone,
  userProfileAddInfo,
  setInfoPermissions,
  aggregatedPhotosCount,
  getPhotosOfYou,
  getYourPhotos,
  getCoverPhotos,
  getProfilePhotos,
  getYourVideos,
  getVideosOfYour,
  unTagPost
} from 'v4/store/actions/user-profile.actions';
import { flattenQueryResults, getNextPageFromURL } from 'v4/utils/utils';
// import { unTagPost } from 'v4/lib/api/post';
export const MyProfileContext = createContext({});

export const useMyProfileContext = ({
  myActvitiesRefetchEnabled = false,
  myExperiencesRefetchEnabled = false,
  myConnectionsRefetchEnabled = false,
  jobPreferencesRefetchEnabled = false,
  mySkillsRefetchEnabled = false,
  myEducationRefetchEnabled = false,
  myMilestonesRefetchEnabled = false,
  myCertificatesRefetchEnabled = false,
  receivedTestimonialsRefetchEnabled = false,
  refetchMy1stDegreeEnabled = false,
}) => {
  const introductionDisc = useDisclosure();
  const audienceDisc = useDisclosure();
  const experienceDisc = useDisclosure();
  const profileDisc = useDisclosure();
  const skillsDisc = useDisclosure();
  const educationDisc = useDisclosure();
  const milestonesDisc = useDisclosure();
  const certificatesDisc = useDisclosure();
  const testimonialsDisc = useDisclosure();
  const activityDisc = useDisclosure();
  const interestsDisc = useDisclosure();
  const causesDisc = useDisclosure();
  const peopleSliderDisc = useDisclosure();

  const [selectedExperience, setSelectedExperience] = useState();
  const [selectedEducation, setSelectedEducation] = useState();
  const [selectedMilestone, setSelectedMilestone] = useState();
  const [selectedCertificate, setSelectedCertificate] = useState();
  const [profileFieldErrors, setProfileFieldErrors] = useState();
  const [permissionValue, setPermissionValue] = useState(null);
  const [activityCount, setActivityCount] = useState(0);
  const [photosOfYouCount, setPhotosOfYouCount] = useState(0);
  const [yourPhotosCount, setYourPhotosCount] = useState(0);
  const [coverPhotoCount, setCoverPhotosCount] = useState(0);
  const [profilePhotoCount, setProfilePhotoCount] = useState(0);
  const [yourVideosCount, setYourVideosCount] = useState(0);
  const [videosOfYouCount, setVideosOfYouCount] = useState(0);
  const [permissionId, setPermissionId] = useState(null);
  const [type, setType] = useState('School');
  const [currentPermissionValue, setCurrentPermissionValue] = useState(1);
  const { userProfileInfo, loading } =
    useSelector((state) => state.userProfile) ?? {};
  const { allDegreeConnections, allDegreeConnectionsArr } =
    useSelector((state) => state.connection) ?? {};

  const dispatch = useDispatch();

  const toast = useToast({
    position: 'top',
    isClosable: true,
  });

  /**
   * My activities
   */
  const {
    data: activityPosts_,
    refetch: refetchActivityPosts,
    hasNextPage: activityPostsHasNextPage,
    fetchNextPage: activityPostsFetchNextPage,
    isFetching: activityPostsIsFetching,
  } = useInfiniteQuery({
    queryKey: ['myActivity'],
    queryFn: async ({ pageParam }) => {
      const resp = await dispatch(
        getMyActivity({ page: pageParam, pageSize: 10 })
      );
      setActivityCount(resp?.payload?.count);
      return resp?.payload;
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: myActvitiesRefetchEnabled,
  });

  /**
   * Who views my profile
   */
  const { data: profileViewers, refetch: refetchProfileViewers } = useQuery({
    queryKey: ['profile-viewers'],
    queryFn: async () => {
      const resp = await dispatch(getProfileViewers({}));
      return resp?.payload?.results ?? [];
    },
    refetchOnWindowFocus: false,
  });

  /**
   * My anayltics
   */
  const {
    data: myAnalytics,
    refetch: refetchMyAnalytics,
    isFetching: myAnalyticsIsFetching,
    isLoading: myAnalyticsIsLoading,
  } = useQuery({
    queryKey: ['myAnalytics'],
    queryFn: async () => {
      const resp = await dispatch(getUserAnalytics({}));
      return resp?.payload?.results ?? [];
    },
    refetchOnWindowFocus: false,
  });

  /**
   * My experiences
   */
  const {
    data: myExperiences,
    refetch: refetchMyExperiences,
    isLoading: myExperiencesLoading,
    isFetching: myExperiencesFetching,
  } = useQuery({
    queryKey: ['myExperiences'],
    queryFn: async () => {
      const resp = await dispatch(getExperience({}));
      return resp?.payload?.results ?? [];
    },
    refetchOnWindowFocus: false,
    enabled: myExperiencesRefetchEnabled,
  });

  /**
   * My skills
   */
  const {
    data: mySkills,
    refetch: refetchMySkills,
    isFetching: mySkillsFetching,
  } = useQuery({
    queryKey: ['mySkills'],
    queryFn: async () => {
      const resp = await dispatch(getProfileSkills({}));
      return resp?.payload ?? [];
    },
    refetchOnWindowFocus: false,
    enabled: mySkillsRefetchEnabled,
  });

  /**
   * My education
   */
  const {
    data: myEducation,
    refetch: refetchMyEducation,
    isFetching: myEducationFetching,
  } = useQuery({
    queryKey: ['myEducation'],
    queryFn: async () => {
      const resp = await dispatch(getEducation({}));
      return resp?.payload?.results ?? [];
    },
    refetchOnWindowFocus: false,
    enabled: myEducationRefetchEnabled,
  });

  /**
   * My milestones
   */
  const {
    data: myMilestones,
    refetch: refetchMyMilestones,
    isFetching: myMilestonesFetching,
  } = useQuery({
    queryKey: ['myMilestones'],
    queryFn: async () => {
      const resp = await dispatch(getMilestone({}));
      return resp?.payload?.results ?? [];
    },
    refetchOnWindowFocus: false,
    enabled: myMilestonesRefetchEnabled,
  });

  /**
   * My certificates
   */
  const {
    data: myCertificates,
    refetch: refetchMyCertificates,
    isFetching: myCertificatesFetching,
  } = useQuery({
    queryKey: ['myCertificates'],
    queryFn: async () => {
      const resp = await dispatch(getCertificate({}));
      return resp?.payload?.results ?? [];
    },
    refetchOnWindowFocus: false,
    enabled: myCertificatesRefetchEnabled,
  });

  const {
    data: aggregatedPhotosData,
    refetch: refetchAgregatedPhotos,
    isFatching: fatchStatusAggregatedPhotos,
  } = useQuery({
    queryKey: ['aggregatedPhotosData'],
    queryFn: async ({ user, company }) => {
      const resp = await dispatch(
        aggregatedPhotosCount({ user: userProfileInfo.slug, company })
      );
      return resp?.payload ?? [];
    },
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: photosOfYou_,
    refetch: refetchPhotosOfyou,
    hasNextPage: photosOfYouHasNextPage,
    fetchNextPage: photosOfYouFetchNextPage,
    isFetching: phototsOfYouIsFetching,
  } = useInfiniteQuery({
    queryKey: ['photosOfYou'],
    queryFn: async ({ slug, pageParam }) => {
      const resp = await dispatch(
        getPhotosOfYou({
          page: pageParam || 1,
          pageSize: 10,
          slug: userProfileInfo.slug,
        })
      );
      setPhotosOfYouCount(resp?.payload?.count);
      return resp?.payload;
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: yourPhotos_,
    refetch: refetchYourPhotos,
    hasNextPage: yourPhotosHasNextPage,
    fetchNextPage: yourPhotosFetchNextPage,
    isFetching: yourPhotosIsFetching,
  } = useInfiniteQuery({
    queryKey: ['yourPhotos'],
    queryFn: async ({ user, pageParam }) => {
      const resp = await dispatch(
        getYourPhotos({
          page: pageParam || 1,
          pageSize: 10,
          user: userProfileInfo.slug,
        })
      );
      setYourPhotosCount(resp?.payload?.count);
      return resp?.payload;
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: coverPhotos_,
    refetch: refetchCoverPhotos,
    hasNextPage: coverPhotosHasNextPage,
    fetchNextPage: coverPhotosFetchNextPage,
    isFetching: coverPhotosIsFetching,
  } = useInfiniteQuery({
    queryKey: ['coverPhotos'],
    queryFn: async ({ user, pageParam }) => {
      const resp = await dispatch(
        getCoverPhotos({
          page: pageParam || 1,
          pageSize: 10,
          user: userProfileInfo.slug,
        })
      );
      setCoverPhotosCount(resp?.payload?.count);
      return resp?.payload;
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: profilePhotos_,
    refetch: refetchProfilePhotos,
    hasNextPage: profilePhotosHasNextPage,
    fetchNextPage: profilePhotosFetchNextPage,
    isFetching: profilePhotosIsFetching,
  } = useInfiniteQuery({
    queryKey: ['profilePhotos'],
    queryFn: async ({ user, pageParam }) => {
      const resp = await dispatch(
        getProfilePhotos({
          page: pageParam || 1,
          pageSize: 10,
          user: userProfileInfo.slug,
        })
      );
      setProfilePhotoCount(resp?.payload?.count);
      return resp?.payload;
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: yourVideos_,
    refetch: refetchyourVideos,
    hasNextPage: yourVideosHasNextPage,
    fetchNextPage: yourVideosFetchNextPage,
    isFetching: yourVideosIsFetching,
  } = useInfiniteQuery({
    queryKey: ['yourVideos'],
    queryFn: async ({ user, pageParam }) => {
      const resp = await dispatch(
        getYourVideos({
          page: pageParam || 1,
          pageSize: 10,
          user: userProfileInfo.slug,
        })
      );
      setYourVideosCount(resp?.payload?.count);
      return resp?.payload;
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: videosOfYou_,
    refetch: refetchvideosOfYou,
    hasNextPage: videosOfYouHasNextPage,
    fetchNextPage: videosOfYouFetchNextPage,
    isFetching: videosOfYouIsFetching,
  } = useInfiniteQuery({
    queryKey: ['videosOfYou'],
    queryFn: async ({ user, pageParam }) => {
      const resp = await dispatch(
        getVideosOfYour({
          page: pageParam || 1,
          pageSize: 10,
          user: userProfileInfo.slug,
        })
      );
      setVideosOfYouCount(resp?.payload?.count);
      return resp?.payload;
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  /**
   * My Testimonials
   */
  const {
    data: myTestimonials_,
    refetch: refetchMyTestimonials,
    isFetching: myTestimonialsFetching,
    fetchNextPage: myTestimonialsFetchNextPage,
    hasNextPage: myTestimonialsHasNextPage,
  } = useInfiniteQuery({
    queryKey: ['my-testimonials'],
    // eslint-disable-next-line no-empty-pattern
    queryFn: async ({}) => {
      try {
        const resp = await dispatch(listOfReceivedTestimonial({}));
        return resp?.payload ?? {};
      } catch (e) {
        console.error(e);
      }
    },
    getNextPageParam: (lastPage) => getNextPageFromURL(lastPage?.next),
    refetchOnWindowFocus: false,
    enabled: receivedTestimonialsRefetchEnabled,
  });

  /**
   * My connections
   */
  const {
    data: myConnections,
    refetch: refetchMyConnections,
    isFetching: myConnectionsFetching,
  } = useQuery({
    queryKey: ['myConnections'],
    queryFn: async () => {
      const resp = await dispatch(getAllConnectionsData());
      return resp?.payload?.results ?? [];
    },
    refetchOnWindowFocus: false,
    enabled: myConnectionsRefetchEnabled,
  });

  /**
   * My job preferences
   */
  const {
    data: jobPreferences,
    refetch: refetchJobPreferences,
    isFetching: jobPreferencesFetching,
  } = useQuery({
    queryKey: ['jobPreferences'],
    queryFn: async () => {
      const resp = await dispatch(getUserJobPreferences());
      return resp?.payload;
    },
    refetchOnWindowFocus: false,
    enabled: jobPreferencesRefetchEnabled,
  });

  /**
   * Update job preferences
   */
  const {
    data: updateJobPreferencesResult,
    isLoading: updateJobPreferencesLoading,
    mutateAsync: updateJobPreferencesAsync,
  } = useMutation({
    mutationKey: ['job-pref-update'],
    mutationFn: async (payload) => {
      return (await dispatch(setUserJobPreferences(payload)))?.payload;
    },
  });

  /**
   * Remove resume
   */
  const {
    data: removeJobPreferencesResumeResult,
    isLoading: removeJobPreferencesResumeLoading,
    mutateAsync: removeJobPreferencesResumeAsync,
  } = useMutation({
    mutationKey: ['job-pref-update-remove-resume'],
    mutationFn: async (payload) => {
      return (await dispatch(removeUserJobPreferencesResume(payload)))?.payload;
    },
  });

  /**
   * Update profile mutation
   */
  const {
    data: profileUpdateResult,
    isLoading: profileUpdateLoading,
    mutateAsync: profileUpdateAsync,
  } = useMutation({
    mutationKey: ['profile-update'],
    mutationFn: async (payload) => {
      return (await dispatch(userProfileAddInfo(payload, true)))?.payload;
    },
  });

  /**
   * add experience mutation
   */
  const {
    data: addExperienceResult,
    isLoading: addExperienceLoading,
    mutateAsync: addExperienceAsync,
  } = useMutation({
    mutationKey: ['profile-add-experience'],
    mutationFn: async (payload) => {
      return (await dispatch(addExperience(payload)))?.payload;
    },
  });

  /**
   * update experience mutation
   */
  const {
    data: updateExperienceResult,
    isLoading: updateExperienceLoading,
    mutateAsync: updateExperienceAsync,
  } = useMutation({
    mutationKey: ['profile-update-experience'],
    mutationFn: async (payload) => {
      return (await dispatch(updateExperience(payload)))?.payload;
    },
  });

  /**
   * remove experience mutation
   */
  const {
    data: removeExperienceResult,
    isLoading: removeExperienceLoading,
    mutateAsync: removeExperienceAsync,
  } = useMutation({
    mutationKey: ['profile-remove-experience'],
    mutationFn: async (payload) => {
      return (await dispatch(deleteExperience(payload)))?.payload;
    },
  });

  /**
   * add skill mutation
   */
  const {
    data: addSkillResult,
    isLoading: addSkillLoading,
    mutateAsync: addSkillAsync,
  } = useMutation({
    mutationKey: ['profile-add-skill'],
    mutationFn: async (payload) => {
      return (await dispatch(addSkill(payload)))?.payload;
    },
  });

  /**
   * remove skill mutation
   */
  const {
    data: removeSkillResult,
    isLoading: removeSkillLoading,
    mutateAsync: removeSkillAsync,
  } = useMutation({
    mutationKey: ['profile-remove-skill'],
    mutationFn: async (payload) => {
      return (await dispatch(deleteSkill(payload?.id)))?.payload;
    },
  });

  /**
   * remove skills mutation
   */
  const {
    data: removeSkillsResult,
    isLoading: removeSkillsLoading,
    mutateAsync: removeSkillsAsync,
  } = useMutation({
    mutationKey: ['profile-remove-skills'],
    mutationFn: async (payload) => {
      return (await dispatch(deleteManySkills(payload)))?.payload;
    },
  });

  /**
   * remove tag
   */
  const {
    data: removeTagResult,
    isLoading: removeTagLoading,
    mutateAsync: removeTagAsync,
  } = useMutation({
    mutationKey: ['profile-post-untag'],
    mutationFn: async (payload) => {
      return (await dispatch(unTagPost(payload)))?.payload
    },
  });

  /**
   * add milestone mutation
   */
  const {
    data: addMilestoneResult,
    isLoading: addMilestoneLoading,
    mutateAsync: addMilestoneAsync,
  } = useMutation({
    mutationKey: ['profile-add-skill'],
    mutationFn: async (payload) => {
      return (await dispatch(addMilestone(payload)))?.payload;
    },
  });

  /**
   * update milestone mutation
   */
  const {
    data: updateMilestoneResult,
    isLoading: updateMilestoneLoading,
    mutateAsync: updateMilestoneAsync,
  } = useMutation({
    mutationKey: ['profile-update-milestone'],
    mutationFn: async (payload) => {
      return (await dispatch(updateMilestone(payload)))?.payload;
    },
  });

  /**
   * remove milestone mutation
   */
  const {
    data: removeMilestoneResult,
    isLoading: removeMilestoneLoading,
    mutateAsync: removeMilestoneAsync,
  } = useMutation({
    mutationKey: ['profile-remove-milestone'],
    mutationFn: async (payload) => {
      return (await dispatch(deleteMilestone(payload)))?.payload;
    },
  });

  /**
   * add certificate mutation
   */
  const {
    data: addCertificateResult,
    isLoading: addCertificateLoading,
    mutateAsync: addCertificateAsync,
  } = useMutation({
    mutationKey: ['profile-add-certi'],
    mutationFn: async (payload) => {
      return (await dispatch(addCertificate(payload)))?.payload;
    },
  });

  /**
   * update certificate mutation
   */
  const {
    data: updateCertificateResult,
    isLoading: updateCertificateLoading,
    mutateAsync: updateCertificateAsync,
  } = useMutation({
    mutationKey: ['profile-update-certi'],
    mutationFn: async (payload) => {
      return (await dispatch(updateCertificate(payload)))?.payload;
    },
  });

  /**
   * remove certificate mutation
   */
  const {
    data: removeCertificateResult,
    isLoading: removeCertificateLoading,
    mutateAsync: removeCertificateAsync,
  } = useMutation({
    mutationKey: ['profile-remove-certi'],
    mutationFn: async (payload) => {
      return (await dispatch(deleteCertificate(payload)))?.payload;
    },
  });

  /**
   * update profile permissions mutation
   */
  const {
    data: updateProfilePermissions,
    isLoading: updateProfilePermissionsLoading,
    mutateAsync: updateProfilePermissionsAsync,
  } = useMutation({
    mutationKey: ['profile-update-permission'],
    mutationFn: async (payload) => {
      return (await dispatch(setInfoPermissions(payload)))?.payload;
    },
  });

  const updateProfilePermissionsAsync_ = async (permission) => {
    // console.log(team);
    try {
      const resp = await updateProfilePermissionsAsync({
        attribute: permission?.attribute,
        audience: permission?.audience, // 1 public | 2 mynetwork | 3 only me
        id: permission?.id,
      });
      if (resp?.detail) {
        toast({
          title: 'Success',
          description: 'Permission Updated!',
          status:'success'
        });

        dispatch(getUserProfileInfo());
        setPermissionValue(null);
        setPermissionId(null);
      } else {
        toast({
          title: 'Error',
          status: 'error',
          description: 'Something went wrong!',
        });
      }
    } catch (e) {
      toast({
        title: 'Error',
        status: 'error',
        description: e?.message ?? 'Some error occuured',
      });
    } finally {
      console.info('done');
    }
  };

  /**
   * add education mutation
   */
  const {
    data: addEducationResult,
    isLoading: addEducationLoading,
    mutateAsync: addEducationAsync,
  } = useMutation({
    mutationKey: ['profile-add-edu'],
    mutationFn: async (payload) => {
      return (await dispatch(addEducation(payload)))?.payload;
    },
  });

  /**
   * update education mutation
   */
  const {
    data: updateEducationResult,
    isLoading: updateEducationLoading,
    mutateAsync: updateEducationAsync,
  } = useMutation({
    mutationKey: ['profile-update-edu'],
    mutationFn: async (payload) => {
      return (await dispatch(updateEducation(payload)))?.payload;
    },
  });

  /**
   * remove education mutation
   */
  const {
    data: removeEducationResult,
    isLoading: removeEducationLoading,
    mutateAsync: removeEducationAsync,
  } = useMutation({
    mutationKey: ['profile-remove-edu'],
    mutationFn: async (payload) => {
      return (await dispatch(deleteEduction(payload)))?.payload;
    },
  });

  // =========== util methods

  /**
   * update profile
   * @param {*} data
   */
  const updateProfile = async (data) => {
    try {
      setProfileFieldErrors({});
      const resp = await profileUpdateAsync(data);
      if (resp?.status_code >= 400) {
        setProfileFieldErrors(resp);
        if (resp?.slug) {
          toast({ title: resp?.slug?.[0], status: 'error' });
        }
        return false;
      }
      dispatch(getUserProfileInfo());
      toast({ title: 'Profile updated successfully!', status: 'success' });
      profileDisc?.onClose?.();
      return true;
    } catch (e) {
      toast({ title: 'Error while updating!', status: 'error' });
    }
  };

  /**
   * update introduction
   * @param {*} data
   */
  const updateIntroduction = async (data) => {
    try {
      const resp = await profileUpdateAsync(data);
      dispatch(getUserProfileInfo());
      toast({ title: 'Profile updated successfully!', status: 'success' });
      introductionDisc?.onClose?.();
    } catch (e) {
      toast({ title: 'Error while updating!', status: 'error' });
    }
  };

  /**
   * add experience
   * @param {*} data
   */
  const addExperience_ = async (data) => {
    try {
      const resp = await addExperienceAsync(data);
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchMyExperiences();
        toast({ title: 'Experience added successfully!', status: 'success' });
        experienceDisc?.onClose?.();
      }
    } catch (e) {
      toast({ title: 'Error while adding new experience!', status: 'error' });
    }
  };

  /**
   * update experience
   * @param {*} data
   */
  const updateExperience_ = async (data) => {
    try {
      const resp = await updateExperienceAsync(data);
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchMyExperiences();
        toast({ title: 'Experience updated successfully!', status: 'success' });
        experienceDisc?.onClose?.();
        setSelectedExperience(null);
      }
    } catch (e) {
      toast({ title: 'Error while updating experience!', status: 'error' });
    }
  };

  /**
   * remove experience
   * @param {*} data
   */
  const removeExperience_ = async (data) => {
    try {
      const resp = await removeExperienceAsync(data?.id);
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchMyExperiences();
        toast({ title: 'Experience removed successfully!', status: 'success' });
        experienceDisc?.onClose?.();
        setSelectedExperience(null);
      }
    } catch (e) {
      toast({ title: 'Error while updating experience!', status: 'error' });
    }
  };

  /**
   * update job preferences
   * @param {*} data
   */
  const updateJobPreferences = async (data) => {
    try {
      const resp = await updateJobPreferencesAsync(data);
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchJobPreferences();
        toast({ title: 'Profile updated successfully!', status: 'success' });
      }
    } catch (e) {
      toast({
        title: 'Error while updating profile preferences!',
        status: 'error',
      });
    }
  };

  /**
   * add skill
   * @param {*} data
   */
  const addSkill_ = async (skill) => {
    try {
      const resp = await addSkillAsync(skill);
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchMySkills();
        toast({ title: 'Skills updated successfully!', status: 'success' });
      }
    } catch (e) {
      toast({
        title: 'Error while updating skills!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  /**
   * remove skill
   * @param {*} data
   */
  const removeSkill = async (skill) => {
    try {
      const resp = await removeSkillAsync(skill);
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchMySkills();
        toast({ title: 'Skill removed successfully!', status: 'success' });
      }
    } catch (e) {
      toast({
        title: 'Error while removing skill!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  /**
   * remove skills
   * @param {*} data
   */
  const removeSkills = async (skills) => {
    try {
      const resp = await removeSkillsAsync(skills);
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchMySkills();
        toast({ title: 'Skill removed successfully!', status: 'success' });
      }
    } catch (e) {
      toast({
        title: 'Error while removing skill!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  const removeTag = async (id) => {
    try {
      const resp = await removeTagAsync(id);
      if ( !resp || resp?.state_code >= 400 ) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchAgregatedPhotos();
        toast({ title: 'Tag removed successfully!', status: 'success' });
      }
    } catch (e) {
      toast({
        title: 'Error while removing Tag!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  /**
   * add milestone
   * @param {*} data
   */
  const addMilestone_ = async (milestone) => {
    try {
      const resp = await addMilestoneAsync(milestone);
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchMyMilestones();
        milestonesDisc?.onClose?.();
        toast({ title: 'Milestones created successfully!', status: 'success' });
      }
    } catch (e) {
      toast({
        title: 'Error while updating milestones!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  /**
   * update milestone
   * @param {*} data
   */
  const updateMilestone_ = async (milestone) => {
    try {
      const resp = await updateMilestoneAsync(milestone);
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchMyMilestones();
        milestonesDisc?.onClose?.();
        toast({ title: 'Milestone updated successfully!', status: 'success' });
      }
    } catch (e) {
      toast({
        title: 'Error while updating milestone!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  /**
   * remove milestone
   * @param {*} data
   */
  const removeMilestone_ = async (milestone) => {
    try {
      const resp = await removeMilestoneAsync(milestone?.id);
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchMyMilestones();
        milestonesDisc?.onClose?.();
        toast({ title: 'Milestone updated successfully!', status: 'success' });
      }
    } catch (e) {
      toast({
        title: 'Error while updating milestone!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  /**
   * add certificate
   * @param {*} data
   */
  const addCertificate_ = async (certificate) => {
    try {
      const resp = await addCertificateAsync(certificate);
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchMyCertificates();
        certificatesDisc?.onClose?.();
        toast({
          title: 'Certificates created successfully!',
          status: 'success',
        });
      }
    } catch (e) {
      toast({
        title: 'Error while updating certificates!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  /**
   * update certificate
   * @param {*} data
   */
  const updateCertificate_ = async (certificate) => {
    try {
      const resp = await updateCertificateAsync(certificate);
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchMyCertificates();
        certificatesDisc?.onClose?.();
        toast({
          title: 'Certificate updated successfully!',
          status: 'success',
        });
      }
    } catch (e) {
      toast({
        title: 'Error while updating certificate!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  /**
   * remove certificate
   * @param {*} data
   */
  const removeCertificate_ = async (certificate) => {
    try {
      const resp = await removeCertificateAsync(certificate?.id);
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchMyCertificates();
        certificatesDisc?.onClose?.();
        toast({
          title: 'Certificate removed successfully!',
          status: 'success',
        });
      }
    } catch (e) {
      toast({
        title: 'Error while removing certificate!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  /**
   * add education
   * @param {*} data
   */
  const addEducation_ = async (education) => {
    try {
      const resp = await addEducationAsync(education);
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchMyEducation();
        educationDisc?.onClose?.();
        toast({
          title: 'Education created successfully!',
          status: 'success',
        });
      }
    } catch (e) {
      toast({
        title: 'Error while updating education!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  /**
   * update education
   * @param {*} data
   */
  const updateEducation_ = async (education) => {
    try {
      const resp = await updateEducationAsync(education);
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchMyEducation();
        educationDisc?.onClose?.();
        toast({
          title: 'Education updated successfully!',
          status: 'success',
        });
      }
    } catch (e) {
      toast({
        title: 'Error while updating education!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  /**
   * remove education
   * @param {*} data
   */
  const removeEducation_ = async (education) => {
    try {
      const resp = await removeEducationAsync(education?.id);
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchMyEducation();
        educationDisc?.onClose?.();
        toast({
          title: 'Education removed successfully!',
          status: 'success',
        });
      }
    } catch (e) {
      toast({
        title: 'Error while removing education!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  /**
   * remove resume
   * @param {*} data
   */
  const removeResume_ = async (resume) => {
    try {
      const resp = await removeJobPreferencesResumeAsync(resume);
      if (resp?.state_code >= 400) {
        toast({ title: 'Invalid details provided!', status: 'error' });
      } else {
        refetchJobPreferences();
        toast({
          title: 'Resume removed successfully!',
          status: 'success',
        });
      }
    } catch (e) {
      toast({
        title: 'Error while removing resume!',
        description: e?.message,
        status: 'error',
      });
    }
  };

  const entityHasStartDates = (entity) => {
    return entity?.start_month && entity?.start_year;
  };
  const entityHasEndDates = (entity) => {
    return entity?.end_month && entity?.end_year;
  };

  const isDateAfter = (m1, y1, m2, y2) => {
    const sd = moment().set('month', m1).set('year', y1);
    const ed = moment().set('month', m2).set('year', y2);
    return sd && ed && sd.isBefore(ed);
  };

  const myTestimonials = flattenQueryResults(myTestimonials_);
  const activityPosts = flattenQueryResults(activityPosts_);
  const photosOfYou = flattenQueryResults(photosOfYou_);
  const yourPhotos = flattenQueryResults(yourPhotos_);
  const coverPhotos = flattenQueryResults(coverPhotos_);
  const profilePhotos = flattenQueryResults(profilePhotos_);
  const yourVideos = flattenQueryResults(yourVideos_);
  const videosOfYou = flattenQueryResults(videosOfYou_);

  const {
    profile_email,
    profile_mobile_number,
    language,
    interested_in,
    causes_you_care,
    profile_intro,
    profile_headline,
  } = userProfileInfo ?? {};
  const hasPersonalInfo =
    profile_email?.length > 0 ||
    profile_mobile_number?.length > 0 ||
    language?.length > 0;

  const hasInterestedIn = interested_in?.length;
  const hasCauses = causes_you_care?.length;

  const hasIntro = profile_intro?.length || profile_headline?.length;
  const hasActivity = activityPosts?.length;
  const hasPhotosOfYou = photosOfYou?.length > 0 ? true : false;
  const hasYourPhotos = yourPhotos?.length > 0 ? true : false;
  const hasCoverPhotos = coverPhotos?.length > 0 ? true : false;
  const hasProfilePhotos = profilePhotos?.length > 0 ? true : false;
  const hasYourVideos = yourVideos?.length > 0 ? true : false;
  const hasVideosOfYou = videosOfYou?.length > 0 ? true : false;

  return {
    isDateAfter,
    userProfileInfo,
    activityPosts,
    refetchActivityPosts,
    activityPostsHasNextPage,
    activityPostsFetchNextPage,
    activityPostsIsFetching,
    introductionDisc,
    experienceDisc,
    profileDisc,
    skillsDisc,
    educationDisc,
    milestonesDisc,
    certificatesDisc,
    testimonialsDisc,
    interestsDisc,
    causesDisc,
    entityHasEndDates,
    entityHasStartDates,
    activityDisc,

    // profile
    updateProfile,
    updateIntroduction,
    profileUpdateLoading,
    profileFieldErrors,
    setProfileFieldErrors,

    // experience
    myExperiences,
    myExperiencesLoading: myExperiencesLoading || myExperiencesFetching,
    refetchMyExperiences,
    addExperience: addExperience_,
    updateExperience: updateExperience_,
    addExperienceLoading,
    selectedExperience,
    setSelectedExperience,
    removeExperience: removeExperience_,
    removeExperienceLoading,

    // network
    myConnections: allDegreeConnectionsArr,
    myConnectionsLoading: myConnectionsFetching,
    refetchMyConnections,

    // job pref
    jobPreferences,
    jobPreferencesFetching,
    refetchJobPreferences,
    updateJobPreferences,
    updateJobPreferencesLoading,
    removeResume: removeResume_,

    // skills
    mySkills,
    mySkillsFetching,
    refetchMySkills,
    addSkill: addSkill_,
    removeSkill,
    removeSkills,
    removeSkillsLoading,
    updateSkillsLoading: addSkillLoading || removeSkillLoading,

    // education
    myEducation,
    myEducationFetching,
    refetchMyEducation,
    setSelectedEducation,
    selectedEducation,
    addEducation: addEducation_,
    updateEducation: updateEducation_,
    addEducationLoading,
    updateEducationLoading,
    removeEducation: removeEducation_,
    removeEducationLoading,

    // milestones
    myMilestones,
    myMilestonesFetching,
    refetchMyMilestones,
    selectedMilestone,
    setSelectedMilestone,
    addMilestone: addMilestone_,
    updateMilestone: updateMilestone_,
    removeMilestone: removeMilestone_,
    removeMilestoneLoading,
    addMilestoneLoading: addMilestoneLoading || updateMilestoneLoading,

    // certificates
    myCertificates,
    myCertificatesFetching,
    refetchMyCertificates,
    selectedCertificate,
    setSelectedCertificate,
    addCertificate: addCertificate_,
    updateCertificate: updateCertificate_,
    addCertificateLoading: addCertificateLoading || updateCertificateLoading,
    allDegreeConnections,
    removeCertificate: removeCertificate_,
    removeCertificateLoading,

    // testimonials
    myTestimonials,
    refetchMyTestimonials,
    myTestimonialsFetching,
    myTestimonialsFetchNextPage,
    myTestimonialsHasNextPage,

    // analytics
    myAnalytics,
    refetchMyAnalytics,
    myAnalyticsIsFetching,
    myAnalyticsIsLoading,

    // proile viewers
    profileViewers,
    refetchProfileViewers,

    //
    hasPersonalInfo,
    hasInterestedIn,
    hasCauses,
    hasIntro,
    hasActivity,
    loading,
    audienceDisc,
    permissionValue,
    setPermissionValue,
    currentPermissionValue,
    setCurrentPermissionValue,
    updateProfilePermissionsAsync: updateProfilePermissionsAsync_,
    permissionId,
    setPermissionId,
    peopleSliderDisc,
    activityCount,

    // photos
    removeTagResult,
    removeTagLoading,
    removeTag,
    aggregatedPhotosData,
    refetchAgregatedPhotos,
    fatchStatusAggregatedPhotos,
    photosOfYouCount,
    hasPhotosOfYou,
    photosOfYou,
    refetchPhotosOfyou,
    photosOfYouHasNextPage,
    photosOfYouFetchNextPage,
    phototsOfYouIsFetching,
    yourPhotos,
    yourPhotosCount,
    hasYourPhotos,
    refetchYourPhotos,
    yourPhotosHasNextPage,
    yourPhotosFetchNextPage,
    yourPhotosIsFetching,
    coverPhotos,
    hasCoverPhotos,
    coverPhotoCount,
    refetchCoverPhotos,
    coverPhotosHasNextPage,
    coverPhotosFetchNextPage,
    coverPhotosIsFetching,
    profilePhotos,
    hasProfilePhotos,
    profilePhotoCount,
    refetchProfilePhotos,
    profilePhotosHasNextPage,
    profilePhotosFetchNextPage,
    profilePhotosIsFetching,
    yourVideos,
    hasYourVideos,
    yourVideosCount,
    refetchyourVideos,
    yourVideosHasNextPage,
    yourVideosFetchNextPage,
    yourVideosIsFetching,
    videosOfYou,
    hasVideosOfYou,
    videosOfYouCount,
    refetchvideosOfYou,
    videosOfYouHasNextPage,
    videosOfYouFetchNextPage,
    videosOfYouIsFetching,

    type,
    setType,
  };
};
